
import ApiService from "@/core/services/ApiService"
import { defineComponent, onMounted, ref, computed } from "vue"
import { SelectModel } from "@/core/models/SelectModel"
import { ElForm } from "element-plus"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { CaseReport } from "@/core/models/CaseReport"

import DxPivotGrid, { DxFieldChooser } from "devextreme-vue/pivot-grid"
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source"

export default defineComponent({
  name: "revenue-enrollment",
  components: { DxPivotGrid, DxFieldChooser },
  setup() {
    type FormInstance = InstanceType<typeof ElForm>
    const ruleFormRef = ref<FormInstance>()

    const appUrl = process.env.VUE_APP_URL ?? ""
    const reportFilter = ref<CaseReport>({
      branchId: "",
      startDate: new Date(new Date().setHours(0, 0, 0, 0)).toLocaleString("lt") as unknown as Date,
      endDate: new Date(new Date().setHours(23, 59, 59, 999)).toLocaleString(
        "lt"
      ) as unknown as Date,
    } as CaseReport)

    const isBusinessSelectLoading = ref(false)
    const isBranchSelectLoading = ref(false)

    const branchList = ref<SelectModel[]>([])
    const businessList = ref<SelectModel[]>([])

    const activeBusinessId = ref([])
    const activeBranchId = ref([])
    const businessDetail = ref([])

    const rules = ref({
      activeBusinessId: getRule(RuleTypes.SELECT, "İşletme"),
      activeBranchId: getRule(RuleTypes.SELECT, "Şube"),
      startDate: getRule(RuleTypes.SELECT, "Başlangıç"),
      endDate: getRule(RuleTypes.SELECT, "Bitiş"),
    })

    const formData = computed(() => ({
      activeBusinessId: activeBusinessId.value,
      activeBranchId: activeBranchId.value.map(x => x[1]),
      ...reportFilter.value,
    }))

    //const disabledDate = computed(() => pickerOptions.disabledDate)

    const OnChangeBusiness = async businessId => {
      branchList.value = []
      await getBranchList(businessId)
    }

    const getBusinessList = async () => {
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (businessList.value.length === 1) {
        //activeBusinessId.value = businessList.value
        await OnChangeBusiness(activeBusinessId.value)
      }
    }

    const getBusinessDetail = async () => {
      const { data } = await ApiService.get("business/detail")
      businessDetail.value = data
        .filter(x => x.branches.length > 0)
        .map(x => ({ branches: x.branches, title: x.title, id: x.id }))

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      businessDetail.value.forEach((element: any) => {
        element.id = null
      })
    }

    const getBranchList = async businessId => {
      isBranchSelectLoading.value = true
      const config = {
        params: {
          businessId: businessId,
        },
      }
      const { data } = await ApiService.query("branch", config)
      isBranchSelectLoading.value = false
      branchList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (branchList.value.length === 1) {
        //activeBranchId.value = branchList.value[0].id
      }
    }

    const formValidate = async formEl => {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          getFinanceReport()
        }
      })
    }

    const getFinanceReport = async () => {
      const config = {
        branchIds: activeBranchId.value.map(x => x[1]),
        startDate: reportFilter.value.startDate,
        endDate: reportFilter.value.endDate,
      }
      const { data } = await ApiService.post("finance/financial-summary", config)
      console.log(data)

      dataSource.value = new PivotGridDataSource({
        fields: [
          {
            caption: "İşletme",
            dataField: "business",
            dataType: "string",
            area: "row",
          },
          /*{
            caption: "Bölge",
            width: 120,
            dataField: "branchGroup",
            area: "row",
          },*/
          {
            caption: "Şube",
            dataField: "branch",
            width: 150,
            area: "row",
          },
          /*           {
            dataField: "registrationDate",
            dataType: "date",
            area: "column",
          }, */
          {
            dataField: "serviceType",
            dataType: "string",
            area: "column",
          },
          {
            caption: "Ciro",
            dataField: "enrollmentFee",
            dataType: "number",
            summaryType: "sum",
            format: {
              currency: "TRY",
              precision: 2,
              type: "currency",
              useCurrencyAccountingStyle: false,
            },
            area: "data",
          },
          {
            caption: "Vadesi Geçen",
            dataField: "overdueAmount",
            dataType: "number",
            summaryType: "sum",
            format: {
              currency: "TRY",
              precision: 2,
              type: "currency",
              useCurrencyAccountingStyle: false,
            },

            area: "data",
          },
          {
            caption: "Ödenen",
            dataField: "paidAmount",
            dataType: "number",
            summaryType: "sum",
            format: {
              currency: "TRY",
              precision: 2,
              type: "currency",
              useCurrencyAccountingStyle: false,
            },
            area: "data",
          },
          {
            caption: "Kayıt Adedi",
            dataField: "contractNumber",
            dataType: "number",
            summaryType: "count",
            area: "data",
          },
        ],
        store: data,
      })
    }

    onMounted(async () => {
      await getBusinessDetail()
    })

    const dataSource = ref()

    const casProps = {
      multiple: true,
      children: "branches",
      label: "title",
      value: "id",
    }

    return {
      appUrl,
      rules,
      formData,
      reportFilter,
      ruleFormRef,
      isBusinessSelectLoading,
      isBranchSelectLoading,
      getBusinessList,
      getBranchList,
      OnChangeBusiness,
      formValidate,
      activeBusinessId,
      activeBranchId,
      branchList,
      businessList,
      dataSource,
      businessDetail,
      casProps,
    }
  },
})
